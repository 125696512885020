<template>
  <v-container fluid>
    <v-row justify="end">
      <v-col cols="auto">
        <v-select
        :items="contractModes"
        return-object
        @input="applyContractMode"
        :value="contractMode"
        :menu-props="{ offsetY: true }"
        :color="color"
        filled
        style="width: 220px;"
        >
          <template #selection="{item}">
            <v-icon :color="item.color" class="mr-2">{{item.icon}}</v-icon>
            <span>{{item.text}}</span>
          </template>
          <template #item="{item}">
            <v-icon small :color="item.color" class="mr-2">{{item.icon}}</v-icon>
            <span style="font-size: 16px">{{item.text}}</span>
          </template>
          <template #prepend>
            <SectionPicker @sections="setVisibleSections" :includeInventory="showInventorySummary"/>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <template v-if="!isByProducts">
      <v-row v-if="sectionVisible(0)" class="pb-2">
        <InventorySummary/>
      </v-row>
      <v-divider class="mb-4 mt-n4 mx-10" v-if="sectionVisible(0) && sectionVisible(1)"/>
      <v-row v-if="sectionVisible(1)" class="py-1">
        <TicketSummaries @data-clicked="handleDataClicked"/>
      </v-row>
      <v-divider class="mb-4 mt-n4 mx-10" v-if="sectionVisible(2) && (sectionVisible(0) || sectionVisible(1))"/>
      <v-row v-if="sectionVisible(2)">
        <OperationalSummary @data-clicked="handleDataClicked"/>
      </v-row>
    </template>

    <template v-else>
      <v-row v-if="sectionVisible(0)" class="pb-2">
        <InventorySummary isByproducts/>
      </v-row>
      <v-divider class="mb-4 mt-n4 mx-10" v-if="sectionVisible(0) && sectionVisible(1)"/>
      <v-row v-if="sectionVisible(1)" class="py-1">
        <ByproductSummaries @data-clicked="handleDataClicked"/>
      </v-row>
    </template>

    <v-row v-if="decksLoading" justify="center" align="center">
      <v-progress-circular class="mt-5" indeterminate color="secondary"/>
    </v-row>
    <v-row v-if="!decksLoading && noSectionsOpen">
      <div style="text-align: center; width: 100%; padding-top: min(175px, 20vh);">
        <img :src="logoPath" alt="Legna Software Logo" height="50vh" class="ml-2" style="opacity: 0.04; color: #449; height: 50vmin; user-select: none;" :draggable="false"/>
      </div>
    </v-row>
    <Dialog :stateId="dialogId" @dialog-closing="resetDialogs" @close="resetDialogs" :enforcePersistence="enforceWidgetTicketsPersistence">
      <WidgetTickets
        :widget="interactiveWidgetData.widget"
        :requestObject="interactiveWidgetData.requestObject"
        :contractMode="contractMode"
        :primaryFilterValue="interactiveWidgetData.primaryFilterValue"
        :secondaryFilterValue="interactiveWidgetData.secondaryFilterValue"
        :primaryFilterKey="interactiveWidgetData.primaryFilterKey"
        :secondaryFilterKey="interactiveWidgetData.secondaryFilterKey"
        :data="interactiveWidgetData.data"
        :details="interactiveWidgetData.details"/>
    </Dialog>
  </v-container>
</template>

<script>
import { ContractModes } from '@/utils/Enumerations.js'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { SETTING_TYPES, SETTING_KEYS } from '../utils/UserSettings.js'
import { uniqueDialogId, colorClassForContractMode } from '@/utils/componentHelpers'
import { DashboardOperationalWidgets } from '@/components/dashboard/WidgetMappings.js'

export default {
  name: 'Dashboard',

  components: {
    SectionPicker: () => import('@/components/dashboard/SectionPicker.vue'),
    InventorySummary: () => import('@/components/dashboard/InventorySummary.vue'),
    TicketSummaries: () => import('@/components/dashboard/TicketSummaries.vue'),
    OperationalSummary: () => import('@/components/dashboard/OperationalSummary.vue'),
    ByproductSummaries: () => import('@/components/dashboard/ByproductSummaries.vue'),
    WidgetTickets: () => import('@/components/dashboard/WidgetTickets.vue'),
    Dialog: () => import('@/components/Dialog.vue')
  },

  data: () => ({
    decksLoading: true,
    sections: [],
    logoPath: './img/Legna-Icon-BLACK.svg',
    contractMode: ContractModes[0],
    interactiveWidgetData: {
      widget: undefined,
      requestObject: undefined,
      primaryFilterValue: undefined,
      secondaryFilterValue: undefined,
      primaryFilterKey: undefined,
      secondaryFilterKey: undefined,
      data: undefined
    },
    dialogId: uniqueDialogId('dashboard-widget-tickets'),
    showInventorySummary: true,
    enforceWidgetTicketsPersistence: false
  }),

  async created () {
    this.contractMode = ContractModes.find(cm => cm.value === this.mutatedUserSettings[SETTING_TYPES.DASHBOARD][SETTING_KEYS.DASHBOARD_CONTRACT_MODE]) ?? ContractModes[0]
    this.setContractMode(this.contractMode)
    this.locationFetch()
  },

  beforeDestroy () {
    this.updateUserSettings(this.mutatedUserSettings)
  },

  computed: {
    ...mapGetters('user-settings', ['mutatedUserSettings']),
    noSectionsOpen () {
      return this.sections.length === 0
    },
    contractModes () {
      return ContractModes.filter(mode => mode.value !== 2)
    },
    isByProducts () {
      return this.contractMode.value === 1
    },
    color () {
      return colorClassForContractMode(this.contractMode.value, false)
    }
  },

  methods: {
    ...mapActions('locations', ['fetchLocations']),
    ...mapActions('user-settings', ['updateUserSettings']),
    ...mapMutations('user-settings', ['mutateUserSetting']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapActions('ticket', ['setContractMode']),
    ...mapActions('dashboard', ['fetchIncompleteTickets']),

    sectionVisible (sectionId) {
      return !this.decksLoading &&
        this.sections.includes(sectionId) &&
        (sectionId !== 0 || this.showInventorySummary || !this.isByProducts)
    },

    setVisibleSections (sections) {
      this.sections = sections
    },

    applyContractMode (contractMode) {
      this.contractMode = contractMode
      this.mutateUserSetting({ type: SETTING_TYPES.DASHBOARD, key: SETTING_KEYS.DASHBOARD_CONTRACT_MODE, value: this.contractMode.value })
      this.setContractMode(contractMode)
      this.locationFetch()
    },

    resetDialogs () {
      if (this.interactiveWidgetData.widget === DashboardOperationalWidgets.IncompleteTickets) {
        this.fetchIncompleteTickets()
      }
      this.closeDialogsAtOrAbove(this.dialogId)
    },

    openDialog () {
      this.enforceWidgetTicketsPersistence = true // Prevents bug if user single-taps for tooltip and then subsequently double-taps
      this.openOrUpdateDialog({ id: this.dialogId, width: '90vw' })
      setTimeout(() => { this.enforceWidgetTicketsPersistence = false }, 500)
    },

    handleDataClicked (e) {
      if (e.data.ticketIds && e.data.ticketIds.length < 1) {
        this.setSnack(this.$t('noTicketsToView'))
        return
      }
      this.interactiveWidgetData = e
      this.openDialog()
    },

    async locationFetch () {
      this.decksLoading = true
      try {
        await this.fetchLocations({ includeDeckHistory: true, includeLogDecks: !this.isByProducts, includeByproductDecks: this.isByProducts })
          .then(locationsResponse => { this.showInventorySummary = locationsResponse.some(location => location.decks.length > 0) }) // Do not show inventory summary if there are no decks
      } finally {
        this.decksLoading = false
      }
    }
  }
}
</script>
